var form;

function submitForm() {

    var formData = new FormData(form);
    formData.append('name', form.querySelector('input[name="name"]').value);
    formData.append('last_name', form.querySelector('input[name="last_name"]').value);
    formData.append('email', form.querySelector('input[name="email"]').value);
    formData.append('phone', form.querySelector('input[name="phone"]').value);
    formData.append('message', form.querySelector('textarea[name="message"]').value);
    formData.append('recaptcha', form.querySelector('input[name="recaptcha"]').value);

    var xhr = new XMLHttpRequest();
    xhr.open('POST', '/api/send.php', true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    xhr.onload = function () {
        // console.log(xhr.response);
        var data = JSON.parse(xhr.response);
        if (data.result) {
            dataLayer.push({
                'event': 'submit',
                'form_submitted': 'conosciamoci_contact'
            });
            if (typeof fbq != 'undefined') {
                fbq('track', 'contact');
            }
        }
        document.getElementById('form-wrapper').innerHTML = data.html;
        initFormContacts();
    };

    xhr.send(formData);
}

function loadRecaptcha() {
    grecaptcha.execute(recaptcha_key,
        { action: 'contact' }).then(function (token) {
        var recaptchaResponse = document.getElementById('contact_recaptcha');
        recaptchaResponse.value = token;
        setTimeout(function () {
            submitForm();
        }, 200);
    });
}

var submitHandlerContacts = function(e) {
    e.preventDefault();
    loadRecaptcha();
    e.target.removeEventListener('submit', submitHandlerContacts, false);
    // document.getElementById('contacts-form-submit').classList.add('d-none');
    // document.getElementById('contacts-form-loader').classList.remove('d-none');
};

function initFormContacts() {
    form = document.getElementById('contacts-form');
    document.getElementById('contacts-form').addEventListener("submit", submitHandlerContacts);
}